import * as React from 'react'
import useUserStore from '../Store/userStore'
import { List, Slide, TextField, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CssBaseline from '@mui/material/CssBaseline'

interface Props {}
const Profile: React.FC<Props> = () => {
  const { user } = useUserStore()
  const { t } = useTranslation()

  if (!user) return null

  return (
    <Slide direction={'left'} in={true} timeout={500}>
      <Box>
        <CssBaseline />
        <List sx={{ gap: '10px', display: 'grid' }}>
          <TextField
            fullWidth
            label={t('profile_fullname')}
            id="fullWidth"
            value={user.name}
            color='secondary'
            //   onChange={(e) => setComment(e.target.value)}
            size="small"
          />
          <TextField
            fullWidth
            label={t('profile_email')}
            id="fullWidth"
            value={user.email}
            color='secondary'
            //   onChange={(e) => setComment(e.target.value)}
            size="small"
          />
        </List>
      </Box>
    </Slide>
  )
}
export default Profile
