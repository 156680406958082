import React from 'react'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useUserStore from '../../Store/userStore'
import { useNavigate, useLocation } from 'react-router-dom'
import Slide from '@mui/material/Slide'
import OauthSignIn from './OauthSignIn'
import CssBaseline from '@mui/material/CssBaseline'
import { useTranslation } from 'react-i18next'
import useEventStore from '../../Store/eventStore'

const Login = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { getUser } = useUserStore()
  const [name, setName] = React.useState('Guest')
  const [password, setPassword] = React.useState('')
  const [hasError, setHasError] = React.useState(false)
  const [hasErrorPassword, setHasErrorPassword] = React.useState(false)
  const { selectedEvent } = useEventStore()
  const [email, setEmail] = React.useState(name.concat(`@momenti${selectedEvent}.app`))

  const handleLogin = async () => {
    if (email !== '' && password !== '') {
      const success = await getUser({ email, password })
      if (success) {
        const sp = new URLSearchParams(location.search)
        const redirectTo = sp.get('redirectTo')
        navigate(redirectTo ? redirectTo : '/')
      }
    }
    setHasError(true)
  }

  const handleNameChange = (name: string) => {
    setName(name)
    if (name === '') {
      setHasError(true)
    } else {
      setHasError(false)
    }
  }

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword)
    if (newPassword === '') {
      setHasErrorPassword(true)
    } else {
      setHasErrorPassword(false)
    }
  }

  const clickRegister = () => {
    navigate(`/register${location.search}`)
  }

  React.useEffect(() => {
    setEmail(name.concat(`@momenti${selectedEvent}.app`))
  }, [name])

  return (
    <Slide direction="left" in={true} timeout={500}>
      <Box
        //   sx={{ '& > :not(style)': { m: 1 } }}
        sx={{
          width: '100%',
          // height: '100%',
          p: '10px 0',
          justifyContent: 'center',
          //   backgroundImage: "url('/static/assets/home_image.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          alignContent: 'center',
          textAlign: '-webkit-center',
        }}
      >
        <CssBaseline />
        <Stack
          component="form"
          spacing={2}
          noValidate
          autoComplete="off"
          style={{
            borderRadius: '10px',
            padding: '40px',
          }}
        >
          <TextField
            required
            error={hasError}
            id="outlined-required"
            variant="standard"
            helperText={hasError ? t('login_error_trye_another_name') : ''}
            label={t('login_name')}
            value={name}
            color="secondary"
            onChange={(e) => handleNameChange(e.target.value)}
          />
          <TextField
            required
            error={hasErrorPassword}
            id="password-outlined-required"
            variant="standard"
            helperText={hasError ? t('login_error_trye_another_password') : ''}
            label={t('login_password')}
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            type="password"
          />
          <Button variant="contained" color={'secondary'} onClick={handleLogin}>
            {t('login_login')}
          </Button>

          {/* <div>{t('login_or')}</div> */}

          {/* <OauthSignIn /> */}
          <Typography variant="subtitle2">
            {t('login_no_account')}{' '}
            <strong onClick={clickRegister}>{t('login_register_here')}</strong>
          </Typography>
        </Stack>
      </Box>
    </Slide>
  )
}

export default Login
