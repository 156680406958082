import * as React from 'react'
import { ThemeProvider, createTheme , Box} from '@mui/material'
import Event from './Pages/Event'
import SelectEvent from './Pages/SelectEvent'
import Login from './Pages/Auth/Login'
import Layout from './Components/Layout/Layout'
import Register from './Pages/Auth/Register'
import useUserStore from './Store/userStore'
import useEventStore from './Store/eventStore'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import SSOCallback from './Pages/Auth/SSOCallback'
import Welcome from './Pages/Welcome'
import Profile from './Pages/Profile'
import SSOLogin from './Pages/Auth/SSOLogin'
import EventList from './Pages/EventList'
import Settings from './Pages/Settings'
import { useNavigate, useLocation } from 'react-router-dom'
import useSettingsStore from './Store/settingsStore'
import AkaAcidBloggerOtf from './assets/ACBloggerRegular.otf'
import AkaAcidBloggerTtf from './assets/ACBloggerRegular.ttf'
import LandingPage from './Pages/LandingPage/LandingPage'

export default function App() {
  const { user, token } = useUserStore()
  const { selectedEvent } = useEventStore()
  const { darkMode } = useSettingsStore()

  const theme = createTheme({
    typography: {
      fontSize: 13,
      fontFamily: ['AkaAcidBlogger', 'Arial', 'Helvetica', 'sans-serif'].join(','),
    },
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#000' : '#fff',
      },
      secondary: {
        main: darkMode ? '#fff' : '#000',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
              @font-face {
                font-family: 'AkaAcidBlogger';
                src: url(${AkaAcidBloggerOtf}) format('opentype'),
                url(${AkaAcidBloggerTtf}) format('truetype');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
              }
            `,
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: 'black'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: "white",
            color: 'black'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            marginBottom: '10px',
            // border: '1px solid #00000014',
            boxShadow: '#00000065 0px 0px 10px -3px',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: '5px 10px',
          },
          subheader: {
            color: 'white'
          }
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '5px 10px',
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '0px 8px',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '48px',
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            height: '48px',
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            width: '48px',
            height: '48px',
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
          },
        },
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <Layout>
                <LoggedOutRoute>
                  <Login />
                </LoggedOutRoute>
              </Layout>
            }
          />
          <Route
            path="/register"
            element={
              <Box>
                <LoggedOutRoute>
                  <Register />
                </LoggedOutRoute>
              </Box>
            }
          />
          <Route
            path=":id"
            element={
              <Box>
                <ProtectedRoute>
                  <SelectEvent />
                </ProtectedRoute>
              </Box>
            }
          />
          <Route
            path="/selectEvent"
            element={
              <Box>
                <ProtectedRoute>
                  <SelectEvent />
                </ProtectedRoute>
              </Box>
            }
          />
          <Route
            path="/profile"
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
          <Route
            path="/settings"
            element={
              <Layout>
                <Settings />
              </Layout>
            }
          />
          <Route
            path="/events"
            element={
              <Layout>
                <EventList />
              </Layout>
            }
          />
          <Route path="/sign-up/sso-callback" element={<SSOCallback />} />
          <Route path="/sign-in/sso-login" element={<SSOLogin />} />
          <Route
            path="/"
            element={
              user && token ? (
                <Box>{selectedEvent ? <Event /> : <Navigate to="/selectEvent" />}</Box>
              ) : (
                <LandingPage />
              )
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useUserStore()

  React.useEffect(() => {
    if (!user) {
      navigate(`/register?redirectTo=${location.pathname}${location.search}`)
    } else {
      navigate('/')
    }
  })

  return children
}

const LoggedOutRoute = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useUserStore()

  React.useEffect(() => {
    if (user) {
      navigate(`/`)
    }
  })

  return children
}
