import * as React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import useEventStore from '../../Store/eventStore'
import { Event } from '../../Models'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { Grid } from '@mui/material'
import ViewMedia from '../MediaList/ViewMedia'
import Skeleton from '@mui/material/Skeleton'
import { Post } from '../../Models'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-cube'
import 'swiper/css/pagination'

import { Autoplay, EffectCube, Pagination } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import ViewMediaSwipe from '../MediaList/ViewMediaSwipe'

interface Props {}

const Ribbon: React.FC<Props> = () => {
  const { selectedEvent, getHighlights, loading } = useEventStore()
  const [highlights, setHighlights] = React.useState([])
  const [selectedHighlight, setSelectedHighlight] = React.useState<Post.Post | null>(null)
  const [selectedMedia, setSelectedMedia] = React.useState<number>(0)

  React.useEffect(() => {
    fetchHighlights()
  }, [])

  const fetchHighlights = async () => {
    const highlights = await getHighlights(selectedEvent)
    setHighlights(highlights)
  }

  const handleItemClick = (highlight: Post.Post, idx: number) => {
    setSelectedHighlight(highlight)
    setSelectedMedia(idx)
  }

  if (highlights && highlights.length === 0) {
    return null
  }
  return (
    <>
      <Swiper
        style={{ padding: '0 10px', overflowY: 'unset' }}
        spaceBetween={10}
        slidesPerView={4}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {!loading ? (
          highlights.map((highlight: Post.Post) => (
            <SwiperSlide key={highlight.id}>
              {highlight.media.length > 0 && (
                <Card sx={{ width: '100%', maxWidth: 600, overflow: 'visible', margin: 0 }}>
                  <Swiper
                    effect={'cube'}
                    grabCursor={true}
                    cubeEffect={{
                      shadow: false,
                      // slideShadows: true,
                      // shadowOffset: 20,
                      // shadowScale: 0.94,
                    }}
                    pagination={true}
                    modules={[Autoplay, EffectCube, Pagination]}
                    className="mySwiper"
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                  >
                    {highlight.media.map((m, idx) => (
                      <SwiperSlide key={m.uuid} onClick={() => handleItemClick(highlight, idx)}>
                        <CardMedia
                          component="img"
                          height="100%"
                          style={{ height: '160px', borderRadius: '10px' }}
                          src={m.urls.full}
                          srcSet={m.srcset}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Card>
              )}
            </SwiperSlide>
          ))
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={3} md={3}>
              <Skeleton variant="rounded" height={160} />
            </Grid>
            <Grid item xs={3} md={3}>
              <Skeleton variant="rounded" height={160} />
            </Grid>
            <Grid item xs={3} md={3}>
              <Skeleton variant="rounded" height={160} />
            </Grid>
            <Grid item xs={3} md={3}>
              <Skeleton variant="rounded" height={160} />
            </Grid>
          </Grid>
        )}
      </Swiper>
      {/* {selectedMedia && <ViewMedia media={selectedMedia} onClose={() => setSelectedMedia(null)} />} */}
        {selectedHighlight && <ViewMediaSwipe selectedMedia={selectedMedia} {...selectedHighlight} onClose={() => setSelectedHighlight(null)} />}
    </>
  )
}
export default Ribbon
