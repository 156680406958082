import * as React from 'react'
import usePostStore from '../../Store/postStore'
import useNavStore from '../../Store/navStore'
import PullToRefresh from 'react-simple-pull-to-refresh'
import Loader from '../Loader/Loader'
import Home from './Home'
import Favorites from './Favorites'
import MyPosts from './MyPosts'
import PostSkeleton from '../Post/PostSkeleton'
import EventList from '../../Pages/EventList'

interface Props {}
const Content: React.FC<Props> = () => {
  const { posts, getPosts, getPrevPosts, loading } = usePostStore()
  const { navigation } = useNavStore()
  const observerTarget = React.useRef(null)

  React.useEffect(() => {
    fetchData()
  }, [])
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        if (entries[0].isIntersecting) {
          await getPrevPosts()
        }
      },
      { threshold: 1 }
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }
  }, [posts, observerTarget])

  const fetchData = async () => {
    await getPosts()
  }

  const handleRefresh = async () => {
    await fetchData()
  }

  let content = null
  switch (navigation.active) {
    case 1:
    default:
      content = <Home swipe={navigation.swipe} />
      break
    case 2:
      content = <Favorites swipe={navigation.swipe} />
      break
    case 4:
      content = <MyPosts swipe={navigation.swipe} />
      break
  }

  return (
    <>
      <PullToRefresh
        onRefresh={handleRefresh}
        refreshingContent={<Loader />}
        pullingContent={<Loader />}
      >
        <>
          {content}
          {/* {loading && <PostSkeleton />} */}
          <div ref={observerTarget} />
        </>
        {/* <>
          <List>
            {filteredPosts.map((post: Post.Post, index: number) => (
              <ViewPost key={index} {...post} />
            ))}
            <MansoryMediaList items={mediaPosts} />
          </List>
          {loading && <PostSkeleton />}
          <div ref={observerTarget} />
        </> */}
      </PullToRefresh>
    </>
  )
}

export default Content
