import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

const cameraStore = (set, get) => ({
  show: false,
  toggleCamera: (show: boolean) =>
    set(() => ({
      show: show,
    })),
  preview: [],
  setPreview: (images: string[]) =>
    set(() => ({
      preview: images,
    })),
  files: [],
  setFiles: (files: string[]) =>
    set(() => ({
      files: files,
    })),
  device: null,
  setDevice: (device) =>
    set(() => ({
      device: device,
    })),
  video: false,
  setVideo: (flag: boolean) =>
    set(() => ({
      video: flag,
    })),
  isFrontCamera: true,
  setIsFrontCamera: (flag: boolean) =>
    set(() => ({
      isFrontCamera: flag,
    })),
  upload: (e: { target: { files: (Blob | MediaSource)[] } }) => {
    try {
      const images_as_base64: string[] = []
      const files: string[] | (Blob | MediaSource)[] = []
      Object.values(e.target.files).forEach((file: string | (Blob | MediaSource)) => {
        images_as_base64.push(URL.createObjectURL(file))
        files.push(file)
      })
      set(() => ({
        preview: images_as_base64,
        files: files,
      }))
    } catch (error) {
      console.error('Error uploading asset:', error)
    }
  },
})

const useCameraStore = create(devtools(cameraStore))

export default useCameraStore
