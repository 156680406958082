import React from 'react'
import CameraIcon from '@mui/icons-material/Camera'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import CollectionsIcon from '@mui/icons-material/Collections'
import CloseIcon from '@mui/icons-material/Close'
import useCameraStore from '../../Store/cameraStore'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'

interface Props {
  webcamRef: React.RefObject<HTMLSelectElement>
  canvasRef: React.RefObject<HTMLSelectElement>
  mediaRecorderRef: React.RefObject<HTMLSelectElement>
}

const CameraButtons: React.FC<Props> = (props) => {
  const { toggleCamera, setPreview, setFiles, video, setVideo, isFrontCamera, setIsFrontCamera } =
    useCameraStore()
  const { t } = useTranslation()
  const [isRecording, setIsRecording] = React.useState<boolean>(false)

  const handleUpload = (e: { target: { files: (Blob | MediaSource)[] } }) => {
    const images_as_base64: string[] = []
    const files: string[] | (Blob | MediaSource)[] = []
    Object.values(e.target.files).forEach((file: string | (Blob | MediaSource)) => {
      images_as_base64.push(URL.createObjectURL(file))
      files.push(file)
    })
    setPreview(images_as_base64)
    setFiles(files)
    toggleCamera(false)
  }

  const handleCameraClick = () => {
    if (video) {
      if (isRecording) {
        stopRecording()
      } else {
        startRecording()
      }
    } else {
      handleCameraShot()
    }
  }

  const startRecording = () => {
    const chunks = []
    props.mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data)
      }
    }
    props.mediaRecorderRef.current.onstop = () => {
      const images_as_base64: string[] = []
      const files: string[] | (Blob | MediaSource)[] = []
      const blob = new Blob(chunks, { type: 'video/mp4' })
      const file = new File([blob], Date.now().toString().concat('.mp4'), { type: 'image/mp4' })
      const videoUrl = URL.createObjectURL(file)
      images_as_base64.push(videoUrl)
      files.push(file)
      setPreview(images_as_base64)
      setFiles(files)
      toggleCamera(false)
    }
    props.mediaRecorderRef.current.start()
    setIsRecording(true)
  }

  const stopRecording = () => {
    props.mediaRecorderRef.current.stop()
    setIsRecording(false)
  }

  const handleCameraShot = () => {
    const context = props.canvasRef.current.getContext('2d')
    context.drawImage(
      props.webcamRef.current,
      0,
      0,
      props.canvasRef.current.width,
      props.canvasRef.current.height
    )
    const imageDataUrl = props.canvasRef.current.toDataURL('image/jpeg')
    // setImageDataUrl(imageDataUrl)
    formatBase64ToBlob(imageDataUrl)
  }
  const formatBase64ToBlob = (image_as_base64) => {
    const images_as_base64: string[] = []
    const files: string[] | File[] = []

    if (props.webcamRef?.current) {
      if (image_as_base64) {
        fetch(image_as_base64)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], Date.now().toString().concat('.jpg'), {
              type: 'image/jpeg',
            })
            console.log(file)
            images_as_base64.push(image_as_base64)
            files.push(file)
            setPreview(images_as_base64)
            setFiles(files)
            toggleCamera(false)
          })
      }
    }
  }

  const switchCamera = () => {
    setIsFrontCamera(!isFrontCamera)
  }

  return (
    <Box sx={{ '& > :not(style)': { p: 2 } }}>
      <Grid container spacing={2} sx={{ position: 'absolute', top: 0, m: 0 }}>
        <Grid
          xs={2}
          md={2}
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Fab
            color="secondary"
            aria-label="close"
            style={{
              backgroundColor: 'transparent',
            }}
            onClick={() => toggleCamera(false)}
          >
            <CloseIcon color="secondary" />
          </Fab>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ position: 'absolute', bottom: 0 }}>
        <Grid xs={12} md={12} sx={{ color: 'white' }}>
          <Chip
            color="secondary"
            disabled={isRecording}
            variant={video ? 'outlined' : 'filled'}
            onClick={() => setVideo(false)}
            label={t('camera')}
          />
          <Chip
            color="secondary"
            disabled={isRecording}
            variant={video ? 'filled' : 'outlined'}
            onClick={() => setVideo(true)}
            label={t('video')}
          />
        </Grid>
        <Grid xs={4} md={4}>
          <input
            accept="image/*,video/*"
            multiple
            id="icon-button-photo"
            onChange={handleUpload}
            type="file"
            style={{ display: 'none' }}
          />
          <label htmlFor="icon-button-photo">
            <Fab
              color="secondary"
              aria-label="add"
              component="span"
              style={{
                backgroundColor: '#ffffff44',
              }}
            >
              <CollectionsIcon color="secondary" />
            </Fab>
          </label>
        </Grid>
        <Grid xs={4} md={4}>
          <Fab
            color="secondary"
            aria-label="add"
            style={{
              backgroundColor: isRecording ? 'red' : '#ffffff44',
            }}
            onClick={() => handleCameraClick()}
          >
            {video ? (
              isRecording ? (
                <StopCircleIcon sx={{ fontSize: 50 }} color="secondary" />
              ) : (
                <PlayCircleFilledIcon sx={{ fontSize: 50 }} color="secondary" />
              )
            ) : (
              <CameraIcon sx={{ fontSize: 50 }} color="secondary" />
            )}
          </Fab>
        </Grid>
        <Grid xs={4} md={4}>
          <Fab
            color="secondary"
            aria-label="switchCamera"
            style={{
              backgroundColor: '#ffffff44',
            }}
            onClick={switchCamera}
          >
            <CameraswitchIcon color="secondary" />
          </Fab>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CameraButtons
