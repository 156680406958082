import * as React from 'react'
import List from '@mui/material/List'
import ViewPost from '../Post/ViewPost'
import usePostStore from '../../Store/postStore'
import { Post } from '../../Models'
import PostSkeleton from '../Post/PostSkeleton'
import Slide from '@mui/material/Slide'

interface Props {
  swipe: 'right' | 'left'
}
const Favorites: React.FC<Props> = (props) => {
  const { getFavoritePosts, loading } = usePostStore()
  const [posts, setPosts] = React.useState<Post.Post[]>([])

  React.useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const favoritePosts = await getFavoritePosts()
    setPosts(favoritePosts)
  }

  return (
    <Slide direction={props.swipe} in={true} timeout={500}>
      <div>
        <List>
          {posts.map((post: Post.Post, index: number) => (
            <ViewPost key={index} {...post} />
          ))}
        </List>
        {loading && <PostSkeleton />}
      </div>
    </Slide>
  )
}

export default Favorites
