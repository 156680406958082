import * as React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ViewMedia from './ViewMedia'
import ViewMediaSwipe from './ViewMediaSwipe'

import { Post } from '../../Models'

interface Props {
  items: ExtendedMedia[]
  post: Post.Post
  fullWidth: boolean
}

interface ExtendedMedia extends Post.Media {
  rows: number
  cols: number
}

const MaxDisplayImgs = 4

const MediaList: React.FC<Props> = (props) => {
  const [selectedMedia, setSelectedMedia] = React.useState<Post.Media | null>(null)
  const [selectedMediaIdx, setSelectedMediaIdx] = React.useState<number>(0)

  const srcset = (item: Post.Media, size: number, rows: number = 2, cols: number = 2) => {
    return {
      src: item.urls?.full ?? item,
      srcSet: item.srcset,
      sizes: `${100 / cols}vw`,
    }
  }

  const handleItemClick = (media: Post.Media, idx: number) => {
    setSelectedMedia(media)
    setSelectedMediaIdx(idx)
  }

  const imageListHeight = 300
  return (
    <>
      <ImageList
        sx={{
          width: '100%',
          maxHeight: `${imageListHeight}px`,
          overflowY: 'hidden',
          alignContent: 'center',
          borderRadius: '10px'
        }}
        variant="quilted"
        cols={props.items.length > 1 ? 2 : 1}
        rowHeight={props.items.length > 2 ? imageListHeight / 2 : imageListHeight}
        gap={10}
      >
        {props.items.length > 0
          ? props.items.slice(0,MaxDisplayImgs).map((item: ExtendedMedia, index: number) => (
              <ImageListItem
                key={item.uuid}
                cols={item.cols || 1}
                rows={item.rows || (index === 1 && props.items.length == 3 ? 2 : 1)}
                sx={{
                  textAlign: '-webkit-center',
                }}
              >
                <img
                  {...srcset(item, 121, item.rows, item.cols)}
                  alt={item.name}
                  loading="lazy"
                  onClick={() => handleItemClick(item, index)}
                  style={{ width: props.fullWidth ? '100%' : 'auto' }}
                />
                  {
                    index === MaxDisplayImgs - 1
                     ? <ImageListItemBar
                     onClick={() => handleItemClick(item, MaxDisplayImgs - 1)}
                     sx={{
                      height: '100%',
                       background:
                         'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                         'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                     }}
                     title={<h1>+{props.items.length - MaxDisplayImgs + 1}</h1>}
                     position="top"
                    //  actionIcon={
                    //    <IconButton
                    //      sx={{ color: 'white' }}
                    //      aria-label={`star ${item.title}`}
                    //    >
                    //      <StarBorderIcon />
                    //    </IconButton>
                    //  }
                     actionPosition="right"
                   />
                     : null
                  }
                  
              </ImageListItem>
            ))
          : null}
      </ImageList>
      {/* {selectedMedia && <ViewMedia media={selectedMedia} onClose={() => setSelectedMedia(null)} />} */}
      {selectedMedia && <ViewMediaSwipe selectedMedia={selectedMediaIdx} {...props.post} onClose={() => setSelectedMedia(null)} />}
      </>
  )
}
export default MediaList
