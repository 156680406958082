import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Post } from '../../Models'
import Fab from '@mui/material/Fab'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'
import SwipeableEdgeDrawer from '../SwipeableDrawer/SwipeableDrawer'
import { Swiper, SwiperSlide } from 'swiper/react'
import Badge from '@mui/material/Badge'
import MapsUgcRoundedIcon from '@mui/icons-material/MapsUgcRounded'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import Comments from '../Post/Comments'
import { saveAs } from 'file-saver';

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-cube'
import 'swiper/css/pagination'

import { EffectCube, Pagination } from 'swiper/modules'
import LikesView from '../Post/Likes'
import { Typography } from '@mui/material'

interface Props {
  media: Post.Post
  selectedMedia: number
  onClose?: () => void
}

const ViewMediaSwipe: React.FC<Props> = (props) => {
  const videoRef = React.useRef()
  const [loop] = React.useState(true)
  const [autoPlay] = React.useState(true)
  const [play, setPlay] = React.useState(autoPlay)
  const [buttonDisplay, setButtonDisplay] = React.useState(true)
  const [expanded, setExpanded] = React.useState(false)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const toggleVideo = (e) => {
    if (play) {
      setPlay(false)
      videoRef.current.pause()
    } else {
      setPlay(true)
      videoRef.current.play()
    }
  }

  const toggleImage = () => {}

  React.useEffect(() => {
    if (play) {
      setTimeout(() => {
        setButtonDisplay(!buttonDisplay)
      }, 500)
    } else {
      setButtonDisplay(!buttonDisplay)
    }
  }, [play])

  const download = () => {
    const imageUrl = props.media[activeSlideIndex].urls.full;  // URL of the image
    const filename = props.media[activeSlideIndex].name;  // Suggested filename for the download

    // Fetch the image and convert it to a Blob
    fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
            // Create a link element
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);  // Create an object URL from the Blob
            link.download = filename;  // Set the download attribute with the filename

            // if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad') || navigator.userAgent.includes('Android')) {
            //   // Special handling for iOS or android
            //   window.open(link.href, '_blank');
            // } else {
              // Append the link to the body (required for Firefox)
              document.body.appendChild(link);

              // Programmatically click the link to trigger the download
              link.click();

              // Remove the link from the document
              document.body.removeChild(link);
            // }

            // Clean up the URL object
            URL.revokeObjectURL(link.href);
        })
        .catch(error => console.error('Error downloading the image:', error));
  }

  const download2 = () => {
    const imageUrl = props.media[activeSlideIndex].urls.full; 
    const filename = props.media[activeSlideIndex].name;  

    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, filename);
      })
      .catch(error => console.error('Error downloading the image:', error));
  };

  if (!props?.media.length) {
    return null
  }
  return (
    <SwipeableEdgeDrawer open={true} onClose={() => props.onClose()} height="100%">
      <Box
        component="form"
        sx={{
          height: '100%',
          alignContent: 'center',
          textAlign: 'center',
          '& > :not(style)': { textAlign: 'center' },
          backgroundColor: 'black',
          overflow: 'hidden',
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2} sx={{ position: 'absolute', top: 0, m: 0 }}>
          <Grid
            xs={2}
            md={2}
            sx={{
              marginLeft: 'auto',
            }}
          >
            <Fab
              color="secondary"
              aria-label="donwload"
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
              onClick={download}
            >
              <DownloadIcon sx={{ color: 'white' }} />
            </Fab>
          </Grid>
          <Grid xs={2} md={2}>
            <Fab
              color="secondary"
              aria-label="close"
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
              onClick={props.onClose}
            >
              <CloseIcon sx={{ color: 'white' }} />
            </Fab>
          </Grid>
        </Grid>
        <Swiper
          effect={'cube'}
          grabCursor={true}
          loop={true}
          initialSlide={props.selectedMedia}
          cubeEffect={{
            shadow: false,
            // slideShadows: true,
            // shadowOffset: 20,
            // shadowScale: 0.94,
          }}
          pagination={true}
          modules={[EffectCube, Pagination]}
          className="mySwiper"
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          onSlideChange={(swiper) => setActiveSlideIndex(swiper.realIndex)}
        >
          {props.media.map((m) => {
            return (
              <SwiperSlide key={m.uuid} style={{ alignSelf: 'center' }}>
                {m.mime.startsWith('video/') ? (
                  <div style={{ position: 'relative' }} onClick={toggleVideo}>
                    <div>
                      <video
                        ref={videoRef}
                        style={{ width: '100%' }}
                        autoPlay={autoPlay}
                        loop={loop}
                      >
                        <source src={m.urls.full} type="video/mp4" onContextMenu="return false;" />
                      </video>
                    </div>
                    <div>
                      {play ? (
                        <Fab
                          color="secondary"
                          aria-label="add"
                          component="span"
                          style={{
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)',
                            left: '50%',
                            top: '50%',
                            opacity: buttonDisplay ? 1 : 0,
                            backgroundColor: '#ffffff44',
                          }}
                        >
                          <PauseIcon />
                        </Fab>
                      ) : (
                        <Fab
                          color="secondary"
                          aria-label="add"
                          component="span"
                          style={{
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)',
                            left: '50%',
                            top: '50%',
                            opacity: buttonDisplay ? 1 : 0,
                            backgroundColor: '#ffffff44',
                          }}
                        >
                          <PlayArrowIcon />
                        </Fab>
                      )}
                    </div>
                  </div>
                ) : (
                  <img
                    style={{ maxWidth: '100vw', maxHeight: '100vh' }}
                    srcSet={m.srcset}
                    sizes="100vw"
                    src={m.urls.full}
                    alt={m.name}
                    loading="lazy"
                    onContextMenu="return false;"
                  />
                )}
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Grid container spacing={2} sx={{ position: 'absolute', bottom: 0, m: 0, zIndex: 2 }}>
          <Grid xs={12} md={12} sx={{ alignContent: 'center' }}>
            <Typography variant="subtitle2" sx={{ color: 'white' }}>
              {props.title}
            </Typography>
            <Grid xs={12} md={12} sx={{ alignContent: 'center' }}></Grid>
            <Typography variant="subtitle2" sx={{ color: 'white' }}>
              {props.content}
            </Typography>
          </Grid>
          <Grid xs={2} md={2} sx={{ alignContent: 'center' }}>
            <LikesView postId={props.id} likes={props.likes} />
          </Grid>
          <Grid
            xs={2}
            md={2}
            sx={{
              marginLeft: 'auto',
            }}
          >
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <Badge
                badgeContent={props.comments.length}
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: 'black',
                    color: 'white',
                    padding: 0,
                    top: '0.74rem',
                    right: '0.74rem',
                    minWidth: '12px',
                    height: '13px',
                  },
                }}
              >
                {expanded ? (
                  <MapsUgcRoundedIcon sx={{ color: 'white' }} />
                ) : (
                  <MapsUgcRoundedIcon sx={{ color: 'white' }} />
                )}
              </Badge>
            </ExpandMore>
          </Grid>
          <Grid xs={12} md={12}>
            <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ textAlign: 'left' }}>
              {/* <CardContent> */}
              <Comments postId={props.id} comments={props.comments} />
              {/* </CardContent> */}
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </SwipeableEdgeDrawer>
  )
}
export default ViewMediaSwipe

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  // transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))
