import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { User } from '../Models'
import service from '../Services'

interface State {
  token: string | null
  user: User.UserResponse | null
  register: (user: User.UserRequest) => void
  socialLogin: (user: User.UserRequest) => void
  logout: () => void
  getUser: (user: User.LoginRequest) => void
}

const getLoggedInToken = (): string | null => {
  const authUser = localStorage.getItem('authUser')
  if (authUser != null && authUser?.length > 0) {
    const userObj = JSON.parse(authUser)
    return userObj.token
  }
  return null
}
const getLoggedInUser = (): User.User | null => {
  const authUser = localStorage.getItem('authUser')
  if (authUser != null && authUser?.length > 0) {
    const userObj = JSON.parse(authUser)
    return userObj.user
  }
  return null
}

const userStore = (set) => ({
  token: getLoggedInToken(),
  user: getLoggedInUser(),
  register: async (user: User.RegisterRequest) => {
    try {
      const newUser: User.UserResponse = await service.register(user)
      localStorage.setItem('authUser', JSON.stringify(newUser))
      if (newUser && newUser.token) {
        set((state: State) => ({
          ssoAuth: false,
          token: newUser.token,
          user: newUser.user,
        }))
        return true
      } else {
        throw new Error('Failed to register')
      }
    } catch (error) {
      console.error('Error on registration:', error)
      return false
    }
  },
  socialLogin: async (user: User.RegisterRequest) => {
    try {
      const newUser = await service.socialLogin(user)
      localStorage.setItem('authUser', JSON.stringify(newUser))
      if (newUser && newUser.token) {
        set((state: State) => ({
          ssoAuth: true,
          token: newUser.token,
          user: newUser.user,
        }))
        return true
      } else {
        throw new Error('Failed to register')
      }
    } catch (error) {
      console.error('Error on registration:', error)
      return false
    }
  },
  getUser: async (user: User.LoginRequest) => {
    try {
      const retrievedUser: User.UserResponse = await service.login(user)
      localStorage.setItem('authUser', JSON.stringify(retrievedUser))
      if (retrievedUser && !retrievedUser.error) {
        set(() => ({
          token: retrievedUser.token,
          user: retrievedUser,
        }))
        return true
      } else {
        throw new Error('Failed to get user')
      }
    } catch (error) {
      console.error('Error fetching user:', error)
    }
  },
  logout: async () => {
    try {
      const authUser = localStorage.getItem('authUser')
      if (authUser != null && authUser?.length > 0) {
        localStorage.removeItem('authUser')
      }
      set(() => ({
        token: null,
        user: null,
      }))
      // } else {
      //   throw new Error('Failed to get user')
      // }
    } catch (error) {
      console.error('Error fetching user:', error)
    }
  },
})

const useUserStore = create(devtools(userStore))

export default useUserStore
