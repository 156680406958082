import { get, post, put, del } from './request'
import { User, Post, Event } from '../Models'

// Auth
export const login = (body: User.LoginRequest): Promise<User.UserResponse> => {
  return post('/login', body)
}
export const register = (body: User.RegisterRequest): Promise<User.UserResponse> => {
  return post('/register', body)
}
export const socialLogin = (body: User.LoginRequest): Promise<User.UserResponse> => {
  return post('/social-login', body)
}

// Event
export const getEvents = (): Promise<Event.EventResponse> => {
  return get('/social-events')
}
export const getEvent = (eventId: string): Promise<Event.Event> => {
  return get('/social-events/getById/' + eventId)
}
export const getHighlights = (eventId: number): Promise<Event.Event> => {
  return get('/social-events/' + eventId + '/highlights')
}

// Posts
export const getPosts = (params: object): Promise<Post.PostResponse> => {
  return get(`/posts`, params)
}
export const getPost = (postId: number): Promise<Post.Post> => {
  return get('/posts/' + postId)
}
export const addPost = (body: Post.PostRequest): Promise<Post.PostResponse> => {
  return post('/posts', body, { contentType: 'multipart/form-data' })
}
export const deletePost = (postId: number): Promise<Post.PostResponse> => {
  return del(`/posts/${postId}`)
}
export const getComments = (postId: number): Promise<Post.Comment[]> => {
  return get(`/posts/${postId}/comments`)
}
export const addComment = (postId: number, body: Post.CommentRequest): Promise<Post.Comment> => {
  return post(`/posts/${postId}/comment`, body)
}

// Like
export const like = (postId: number) => {
  return post(`/like/post/${postId}`)
}
