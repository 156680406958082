import * as React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ShareIcon from '@mui/icons-material/Share'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ViewModal from '../Modal/ViewModal'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { Post } from '../../Models'
import MediaList from '../MediaList/MediaList'
import LikesView from './Likes'
import Comments from './Comments'
import usePostStore from '../../Store/postStore'
import Moment from '../Moment/Moment'
import { useTranslation } from 'react-i18next'
import useUserStore from '../../Store/userStore'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import Badge from '@mui/material/Badge'
import MapsUgcRoundedIcon from '@mui/icons-material/MapsUgcRounded'
import { CssBaseline } from '@mui/material'

const Loader: React.FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <CircularProgress />
  </Box>
)

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  // transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

interface Props extends Post.Post {}

const ViewPost: React.FC<Props> = (props) => {
  const { deletePost } = usePostStore()
  const { user } = useUserStore()
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState(false)
  const [showConfirmAction, setShowConfirmAction] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    handleClose()
    setShowConfirmAction(true)
  }

  const handleConfirmDelete = async () => {
    setIsLoading(true)
    await deletePost(props.id)
    setIsLoading(false)
    setShowConfirmAction(false)
  }

  return (
    <Card sx={{ width: '100%' }}>
      <Box sx={{ position: 'relative' }}>
        <Grid
          container
          sx={{
            position: 'absolute',
            top: 0,
            zIndex: 2,
            color: 'white',
            width: '100%',
            height: '100%',
            background: 'radial-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0.9) 100%)',
            // background: 'radial-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0.9) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.1), transparent), linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent)',
            pointerEvents: 'none',
          }}
        >
          <Grid xs={6} md={6}>
            <CardHeader
              avatar={
                props.creator.image ? (
                  <Avatar alt={props.creator.name} src={props.creator.image} sx={{pointerEvents: 'auto'}}/>
                ) : (
                  <Avatar alt={props.creator.name} sx={{pointerEvents: 'auto'}}>
                    {props.creator.name.substring(0, 1).toUpperCase()}
                  </Avatar>
                )
              }
              action={
                props.creator.name === user?.name ? (
                  <>
                    <IconButton aria-label="settings" onClick={handleMenu}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>{t('edit')}</MenuItem>
                      <MenuItem onClick={handleDelete}>{t('delete')}</MenuItem>
                    </Menu>
                  </>
                ) : null
              }
              title={props.creator.name}
              subheader={<Moment format="fromNow" date={props.date} />}
            />
          </Grid>
          <Grid xs={6} md={6}>
            <CardContent>
              <Typography sx={{textAlign: 'right'}}>{props.title}</Typography>
            </CardContent>
          </Grid>
        </Grid>
        <MediaList items={props.media} post={{ ...props }} fullWidth={true} />
        <Grid
          container
          sx={{
            position: 'absolute',
            bottom: 0,
            p: '0 10px!important',
            color: 'white',
            zIndex: 2,
            pointerEvents: 'none',
          }}
        >
          <Grid xs={10} md={10} sx={{ alignContent: 'center' }}>
            <CardContent variant="body2" color="text.secondary" sx={{ p: '0px!important' }}>
              <Typography variant="body2">{props.content}</Typography>
            </CardContent>
          </Grid>
          <Grid xs={2} md={2} sx={{ pointerEvents: 'auto' }}>
            <CardActions disableSpacing>
              <LikesView postId={props.id} likes={props.likes} />
              {/* <IconButton aria-label="share">
                <ShareIcon />
              </IconButton> */}
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <Badge
                  badgeContent={props.comments.length}
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: 'black',
                      color: 'white',
                      padding: 0,
                      top: '0.74rem',
                      right: '0.74rem',
                      minWidth: '12px',
                      height: '13px',
                    },
                  }}
                >
                  {expanded ? <MapsUgcRoundedIcon sx={{color: 'white'}}/> : <MapsUgcRoundedIcon sx={{color: 'white'}}/>}
                </Badge>
              </ExpandMore>
            </CardActions>
          </Grid>
        </Grid>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Comments postId={props.id} comments={props.comments} />
        </CardContent>
      </Collapse>
      {showConfirmAction && (
        <ViewModal onClose={() => setShowConfirmAction(false)}>
          {isLoading ? (
            <Loader />
          ) : (
            <Box sx={{ height: '100px', alignContent: 'center' }}>
              <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                <Grid xs={12} md={12}>
                  <Typography>{t('delete_post_confirm_message')}</Typography>
                </Grid>
                <Grid xs={6} md={6}>
                  <Button variant={'contained'} color={'secondary'} onClick={handleConfirmDelete}>
                    {t('yes')}
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={() => setShowConfirmAction(false)}
                  >
                    {t('no')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </ViewModal>
      )}
    </Card>
  )
}
export default ViewPost
