import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import { useTranslation } from 'react-i18next'
import UploadButton from '../Camera/UploadButton'

export default function FloatingUpload() {
    const { t } = useTranslation()

  return (
    <Box sx={{ 
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        textAlign: '-webkit-center',
        zIndex: 3,
        pointerEvents: 'none',
        '& > :not(style)': { m: 1, width: '200px', borderRadius: '10px', justifySelf: 'center' } }}>
      <Fab  color="secondary"
        sx={{
          borderRadius: '10px 10px 0 0',
          zIndex: 2,
          pointerEvents: 'auto'
        }}>
        <UploadButton label={<Typography sx={{padding: '5px 0 0 5px'}}>{t('upload')}</Typography>}/>
      </Fab>
    </Box>
  )
}
