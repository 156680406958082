import * as React from 'react'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useUserStore from '../../Store/userStore'
import { useNavigate, useLocation } from 'react-router-dom'
import Slide from '@mui/material/Slide'
import OauthSignIn from './OauthSignIn'
import CssBaseline from '@mui/material/CssBaseline'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import HeaderMenu from '../../Components/Header/HeaderMenu';
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Moment from '../../Components/Moment/Moment'
import useEventStore from '../../Store/eventStore'
import { Event } from '../../Models'

interface Props {}
const Register: React.FC<Props> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { register } = useUserStore()
  const { t } = useTranslation()
  const [name, setName] = React.useState('')
  const [password] = React.useState('123456')
  const [hasError, setHasError] = React.useState(false)
  const { selectedEvent, event } = useEventStore()
  const [email, setEmail] = React.useState(name.concat(`@momenti${selectedEvent}.app`))

  const handleRegister = async () => {
    const success = await register({ name, email, password })
    if (success) {
      navigate('/')
      setHasError(false)
    } else {
      setHasError(true)
    }
  }
  const replaceGreekWithEnglish = (word: string): string => {
    const greekToEnglishMap = {
        'Α': 'A', 'Β': 'B', 'Γ': 'G', 'Δ': 'D', 'Ε': 'E', 'Ζ': 'Z', 'Η': 'H', 'Θ': 'Th',
        'Ι': 'I', 'Κ': 'K', 'Λ': 'L', 'Μ': 'M', 'Ν': 'N', 'Ξ': 'X', 'Ο': 'O', 'Π': 'P',
        'Ρ': 'R', 'Σ': 'S', 'Τ': 'T', 'Υ': 'Y', 'Φ': 'Ph', 'Χ': 'Ch', 'Ψ': 'Ps', 'Ω': 'O',
        'α': 'a', 'β': 'b', 'γ': 'g', 'δ': 'd', 'ε': 'e', 'ζ': 'z', 'η': 'h', 'θ': 'th',
        'ι': 'i', 'κ': 'k', 'λ': 'l', 'μ': 'm', 'ν': 'n', 'ξ': 'x', 'ο': 'o', 'π': 'p',
        'ρ': 'r', 'σ': 's', 'ς': 's', 'τ': 't', 'υ': 'y', 'φ': 'ph', 'χ': 'ch', 'ψ': 'ps', 'ω': 'o'
    };

    return word.replace(/[Α-Ωα-ω]/g, (match) => {
        return greekToEnglishMap[match] || match;
    });
}

  const handleNameChange = (name: string) => {
    setName(name)
    if (name === '') {
      setHasError(true)
    } else {
      setHasError(false)
    }
  }

  const clickLogin = () => {
    navigate(`/login${location.search}`)
  }

  React.useEffect(() => {
    setEmail(replaceGreekWithEnglish(name.replace(/\s/g, '').toLocaleLowerCase()).concat(`@momenti${selectedEvent}.app`))
  }, [name])

  return (
    <Slide direction="right" in={true} timeout={500}>
      <Box
        //   sx={{ '& > :not(style)': { m: 1 } }}
        sx={{
          width: '100%',
          // height: '100%',
          // p: '10px 0',
          justifyContent: 'center',
          //   backgroundImage: "url('/static/assets/home_image.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          alignContent: 'center',
          textAlign: '-webkit-center',
          display: 'flex',
          height: '100dvh',
          marginTop: 'auto'
        }}
      >
          <CssBaseline />
          <Box sx={{position: 'relative', bottom: 0, width: '100%'}}>
            {event ? 
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <Card
              sx={{ width: '100%', borderRadius: 0, m: 0 }}
              // onClick={() => setShowEventDetails(!showEventDetails)}
            >
              <Box sx={{ position: 'relative' }}>
                <CardMedia
                  component="img"
                  sx={{height: '100dvh'}}
                  src={event.media[0]?.urls.full}
                  srcSet={event.media[0]?.srcset}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    bgcolor: 'rgba(0, 0, 0, 0.54)',
                    color: 'white',
                    padding: '5px 10px',
                  }}
                >
                  <Grid container sx={{height: '100%'}}>
                    <Grid xs={10} md={10}>
                      <Typography variant="h5" sx={{textAlign: 'left'}}>{event.title}</Typography>
                    </Grid>
                    <Grid xs={2} md={2}>
                      <HeaderMenu />
                    </Grid>
                    <Grid xs={9} md={9}>
                      <Typography variant="body2" sx={{textAlign: 'left'}}>
                        <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
                      </Typography>
                    </Grid>
                    <Grid xs={3} md={3} sx={{ marginLeft: 'auto', alignContent: 'center' }}>
                      <Typography variant="body2">
                        <Moment format="fromNow" date={event.date} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Card>
          </Box>
            : null}
            
          <Stack
            component="form"
            spacing={2}
            noValidate
            autoComplete="off"
            sx={{
              // borderRadius: '10px',
              padding: '20px',
              marginTop: 'auto',
              width: '100%',
              position: 'absolute',
              bottom: '0',
              bgcolor: 'rgba(0, 0, 0, 0.54)',
            }}
          >
            <Typography variant="h6" sx={{color: 'white'}}>
              {t('register_main_message')}
            </Typography>
            <TextField
              required
              error={hasError}
              id="outlined-required"
              helperText={hasError ? t('login_error_trye_another_name') : ''}
              label={t('register_name')}
              value={name}
              color='secondary'
              onChange={(e) => handleNameChange(e.target.value)}
            />
            <Button variant="contained" color={'secondary'} onClick={handleRegister}>
              {t('register_register')}
            </Button>

            {/* <div>{t('register_or')}</div> */}

            {/* <OauthSignIn t={t} /> */}
            <Typography variant="subtitle2" sx={{color: 'white'}}>
              {t('register_have_account')}{' '}
              <strong onClick={clickLogin}>{t('register_login_here')}</strong>
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Slide>
  )
}
export default Register
