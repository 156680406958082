import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

interface Props {
  children: React.ReactNode
  onClose?: () => void
  style?: object
}

const ViewModal: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(true)
  const handleClose = () => {
    setOpen(false)
    props.onClose()
  }

  React.useEffect(() => {
    setOpen(true)
  }, [])

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    textAlign: 'center',
    bgcolor: 'black',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,

    ...props.style,
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>{props.children}</Box>
      </Modal>
    </div>
  )
}

export default ViewModal
