import * as React from 'react'
import Badge from '@mui/material/Badge'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Likes } from '../../Models/post'
import usePostStore from '../../Store/postStore'
import useUserStore from '../../Store/userStore'

interface Props {
  postId: number
  likes: Likes
}

const LikesView: React.FC<Props> = (props) => {
  const { user } = useUserStore()
  const { like } = usePostStore()
  const [likes, setLikes] = React.useState(props.likes)
  const [liked, setLiked] = React.useState(props.likes.users.filter((u) => u.name !== user?.name))

  const makeLike = async () => {
    await like(props.postId)
  }

  React.useEffect(() => {
    const hasMyLike: boolean = props.likes.users.map((u) => u.name).includes(user?.name)
    setLiked(hasMyLike)
  }, [props.likes])

  return (
    <Badge badgeContent={props.likes.count}  sx={{color: 'white'}} onClick={makeLike}>
      {liked ? <StarIcon  sx={{color: 'white'}} /> : <StarBorderIcon  sx={{color: 'white'}} />}
    </Badge>
  )
}

export default LikesView
