import { create } from 'zustand'
import useCameraStore from './cameraStore'

const { toggleCamera } = useCameraStore.getState()

// Define the state type
interface State {
  navigation: Navigation
  setActive: (active: number) => void
}

interface Navigation {
  active: number
  swipe: 'right' | 'left'
}

const useNavStore = create<State>((set, get) => ({
  navigation: {
    active: 1,
    swipe: 'left',
  },
  setActive: (active: number) => {
    toggleCamera(active === 3)
    const prevNavActive = get().navigation.active
    const swipe = prevNavActive < active ? 'left' : 'right'
    set((state: State) => ({
      navigation: { ...state.navigation, active, swipe },
    }))
  },
}))

export default useNavStore
