import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from './locales/en.json'
import translationEL from './locales/el.json'

const resources = {
  en: {
    translation: translationEN,
  },
  el: {
    translation: translationEL,
  },
}

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'el',
    debug: true,
    lng: localStorage.getItem('i18nextLng') || 'el',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
