import * as React from 'react'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import SendIcon from '@mui/icons-material/Send'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import useEventStore from '../Store/eventStore'
import { useNavigate } from 'react-router-dom'
import Slide from '@mui/material/Slide'
import CssBaseline from '@mui/material/CssBaseline'
import Loader from '../Components/Loader/Loader'
import useUserStore from '../Store/userStore'

export default function SelectEvent() {
  const navigate = useNavigate()
  const { getEvent } = useEventStore()
  const { user } = useUserStore()
  const [eventNumber, setEventNumber] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [hasError] = React.useState(false)

  const fetchEvent = async (eventNum: string) => {
    if (eventNum) {
      const retrievedEvent = await getEvent(eventNum)
      if (retrievedEvent) {
        // if (user) {
        //   navigate('/')
        // } else {
        //   const sp = new URLSearchParams(location.search)
        //   const redirectTo = sp.get('redirectTo')
        //   navigate('/register'+redirectTo)
        // }
      }
      setLoading(false)
    }
  }

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const uuid = pathname.replace(/\//g, "");
    // const sp = new URLSearchParams(window.location.search)
    // const eventNum = sp.get('event')
    if (uuid) {
      fetchEvent(uuid)
    } else {
      setLoading(false)
    }
  }, [])

  return (
    loading
    ? <Loader/>
    : <Slide direction="down" in={true} timeout={500}>
      <Box
        //   sx={{ '& > :not(style)': { m: 1 } }}
        sx={{
          width: '100%',
          // height: '100%',
          p: '10px 0',
          justifyContent: 'center',
          //   backgroundImage: "url('/static/assets/home_image.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          alignContent: 'center',
          textAlign: '-webkit-center',
        }}
      >
        <CssBaseline />
        <Stack
          component="form"
          sx={{
            width: '25ch',
          }}
          spacing={2}
          noValidate
          autoComplete="off"
          style={{
            borderRadius: '10px',
            padding: '40px',
            width: '100%',
          }}
        >
          <TextField
            required
            error={hasError}
            id="outlined-required"
            variant="filled"
            placeholder="325564"
            label="Give the event number"
            value={eventNumber}
            onChange={(e) => setEventNumber(e.target.value)}
            fullWidth
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={() => fetchEvent(eventNumber)}
            endIcon={<SendIcon />}
          >
            Send
          </Button>
        </Stack>
      </Box>
    </Slide>
  )
}
