import React, { ReactElement } from 'react'
import useCameraStore from '../../Store/cameraStore'
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined'

interface Props {
  label?: string | ReactElement
}
const UploadButton: React.FC<Props> = (props) => {
  const { upload } = useCameraStore()

  return (
    <div style={{ height: '100%' }}>
      <input
        accept="image/*,video/*"
        multiple
        id="icon-button-photo"
        onChange={upload}
        type="file"
        style={{ display: 'none' }}
      />
      <label
        htmlFor="icon-button-photo"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          placeItems: 'center',
        }}
      >
        <AddAPhotoOutlinedIcon />
        {props.label && props.label}
      </label>
    </div>
  )
}
export default UploadButton
