import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { useTranslation } from 'react-i18next'

export default function Pricing() {
  const { t } = useTranslation()

  const tiers = [
    {
      title: t('landing_page_pricing_basic'),
      price: null,
      description: [
        t('landing_page_pricing_feature_1'),
        t('landing_page_pricing_feature_2'),
        t('landing_page_pricing_feature_3'),
        t('landing_page_pricing_feature_4'),
        t('landing_page_pricing_feature_5'),
        t('landing_page_pricing_feature_6')?.replace('%s', '7'),
        t('landing_page_pricing_feature_7')
      ],
      buttonText: t('landing_page_pricing_contact_us'),
      buttonVariant: 'outlined',
    },
    {
      title: t('landing_page_pricing_pro'),
      subheader: 'Recommended',
      price: null,
      description: [
        t('landing_page_pricing_feature_1'),
        t('landing_page_pricing_feature_2'),
        t('landing_page_pricing_feature_3'),
        t('landing_page_pricing_feature_4'),
        t('landing_page_pricing_feature_5'),
        t('landing_page_pricing_feature_6')?.replace('%s', '7'),
        t('landing_page_pricing_feature_7')
      ],
      buttonText: t('landing_page_pricing_contact_us'),
      buttonVariant: 'contained',
    },
    {
      title: t('landing_page_pricing_enterprise'),
      price: null,
      description: [
        t('landing_page_pricing_feature_1'),
        t('landing_page_pricing_feature_2'),
        t('landing_page_pricing_feature_3'),
        t('landing_page_pricing_feature_4'),
        t('landing_page_pricing_feature_5'),
        t('landing_page_pricing_feature_6')?.replace('%s', '30'),
        t('landing_page_pricing_feature_7')
      ],
      buttonText: t('landing_page_pricing_contact_us'),
      buttonVariant: 'outlined',
    },
  ]
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {t('landing_page_pricing_title')}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t('landing_page_pricing_description')}
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid item key={tier.title} xs={12} sm={tier.title === t('landing_page_pricing_enterprise') ? 12 : 6} md={4}>
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === t('landing_page_pricing_pro') ? '1px solid' : undefined,
                borderColor: tier.title === t('landing_page_pricing_pro') ? 'primary.main' : undefined,
                background:
                  tier.title === t('landing_page_pricing_pro') ? 'linear-gradient(#033363, #021F3B)' : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: tier.title === t('landing_page_pricing_pro') ? 'grey.100' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === t('landing_page_pricing_pro') && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) => (theme.palette.mode === 'light' ? '' : 'none'),
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: tier.title === t('landing_page_pricing_pro') ? 'grey.50' : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    {tier.price !== null
                      ? tier.price+'€'
                      : null}
                    
                  </Typography>
                  <Typography component="h3" variant="h6">
                    {tier.price !== null
                      ? 'per month'
                      : null}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: tier.title === t('landing_page_pricing_pro') ? 'primary.light' : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color: tier.title === t('landing_page_pricing_pro') ? 'grey.200' : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as 'outlined' | 'contained'}
                  component="a"
                  href={`mailto:hello@momenti.app?subject=${t('landing_page_pricing_mail_subject')?.replace(' ', '%20')}&body=${t('landing_page_pricing_mail_body')?.replace('%s', tier.title)}`}
                  target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
