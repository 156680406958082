import * as React from 'react'
import { Slide, List } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import DarkMode from '../Components/DarkMode/DarkMode'
import Language from '../Components/Language/Language'

interface Props {}
const Settings: React.FC<Props> = () => {
  return (
    <Slide direction={'left'} in={true} timeout={500}>
      <Box>
        <CssBaseline />
        <List sx={{ gap: '10px', display: 'grid' }}>
          <Language />
          <DarkMode />
        </List>
      </Box>
    </Slide>
  )
}
export default Settings
