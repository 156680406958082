import React from 'react'
import useCameraStore from '../../Store/cameraStore'
import CameraVideo from './CameraVideo'

const videoConstraints = {
  width: '100vw',
  height: '100vh',
  // width: 1280,
  // height: 1024,
  facingMode: 'user',
  // facingMode: { exact: 'environment' },
}

interface Props {}

const Camera: React.FC<Props> = () => {
  const { show, setDevice } = useCameraStore()
  const webcamRef = React.useRef(null)

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      setDevice(mediaDevices.find(({ kind }) => kind === 'videoinput'))
    },
    [setDevice]
  )

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices)
  }, [handleDevices])

  if (!show) return null

  return <CameraVideo />
}

export default Camera
