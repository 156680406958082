import * as React from 'react'
import Box from '@mui/material/Box'
import Header from '../../Components/Header/Header'

interface Props {
  children: React.ReactNode
}
const Layout: React.FC<Props> = (props) => {
  return (
    <Box>
      <Header />
      {props.children}
    </Box>
  )
}
export default Layout
