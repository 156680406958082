import * as React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ViewMedia from './ViewMedia'
import Box from '@mui/material/Box'
import ViewMediaSwipe from './ViewMediaSwipe'

import { Post } from '../../Models'

interface Props {
  items: Post.Media[]
  posts: Post.Post[]
}

interface ExtendedMedia extends Post.Media {
  rows: number
  cols: number
}

const MansoryMediaList: React.FC<Props> = (props) => {
  const [selectedMedia, setSelectedMedia] = React.useState<Post.Media | null>(null)
  const [selectedMediaIdx, setSelectedMediaIdx] = React.useState<number>(0)

  const srcset = (item: Post.Media, size: number, rows: number = 2, cols: number = 2) => {
    return {
      src: item.urls?.full ?? item,
      srcSet: item.srcset,
      sizes: `${100 / cols}vw`,
    }
  }

  const handleItemClick = (media: Post.Media, idx: number) => {
    setSelectedMedia(media)
    setSelectedMediaIdx(idx)
  }

  const selectedPost = props.posts.findIndex((post: Post.Post) => post.media.filter((m: Post.Media) => m.id == selectedMediaIdx).length > 0)

  return (
    <>
      <Box>
        <ImageList variant="masonry" cols={3} gap={8}>
          {props.items.length > 0
            ? props.items.map((item: ExtendedMedia, index: number) => (
                <ImageListItem key={item.uuid}>
                  <img
                    {...srcset(item, 121, item.rows, item.cols)}
                    alt={item.name}
                    loading="lazy"
                    onClick={() => handleItemClick(item, index)}
                    style={{
                      borderRadius: '10px'}}
                  />
                  {/* {
                    item.title.length > 0
                     ? <ImageListItemBar
                    //  onClick={() => handleItemClick(item, MaxDisplayImgs - 1)}
                     sx={{
                       background:
                         'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                         'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                     }}
                     title={<h1>{items.text}</h1>}
                     position="top"
                    //  actionIcon={
                    //    <IconButton
                    //      sx={{ color: 'white' }}
                    //      aria-label={`star ${item.title}`}
                    //    >
                    //      <StarBorderIcon />
                    //    </IconButton>
                    //  }
                     actionPosition="right"
                   />
                     : null
                  } */}
                </ImageListItem>
              ))
            : null}
        </ImageList>
      </Box>
      {/* {selectedMedia && <ViewMedia media={selectedMedia} onClose={() => setSelectedMedia(null)} />} */}
      {selectedMedia && <ViewMediaSwipe selectedMedia={selectedMediaIdx} {...selectedPost} onClose={() => setSelectedMedia(null)} />}

    </>
  )
}
export default MansoryMediaList
