import * as React from 'react'
import usePostStore from '../../Store/postStore'
import { Post } from '../../Models'
import MansoryMediaList from '../MediaList/MansoryMediaList'
import Slide from '@mui/material/Slide'

interface Props {
  swipe: 'right' | 'left'
}
const MyPosts: React.FC<Props> = (props) => {
  const { getMyPosts } = usePostStore()
  const [media, setMedia] = React.useState<Post.Media[]>([])
  const observerTarget = React.useRef(null)

  React.useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    const myPosts = getMyPosts()

    const mediaPosts: Post.Media[] = []
    myPosts.filter((post: Post.Post) => {
      Object.keys(post.media).forEach((key: string) => {
        mediaPosts.push(post.media[parseInt(key)])
      })
    })
    setMedia(mediaPosts)
  }
  return (
    <Slide direction={props.swipe} in={true} timeout={500}>
      <div>
        <MansoryMediaList items={media} />
        <div ref={observerTarget} />
      </div>
    </Slide>
  )
}

export default MyPosts
