import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Event, Pagination } from '../Models'
import service from '../Services'

interface State {
  events: Event[],
  event: Event,
  selectedEvent: number | null
  addEvent: (event: Event) => void
  getEvent: (eventId: number) => Event.Event
  getHighlights: (eventId: number) => Event.Event
  getSelectedEvent: (number) => Event.Event
  setSelectedEvent: (eventId: string) => void
  loading: boolean
  setLoading: (flag: boolean) => void
  pagination: Pagination
}

const getSelectedEventId = () => {
  const selectedEventId = localStorage.getItem('eventId')
  if (selectedEventId != null && selectedEventId?.length > 0) {
    return JSON.parse(selectedEventId)
  }
  return null
}

const eventStore = (set, get) => ({
  events: [],
  event: null,
  selectedEvent: getSelectedEventId(),
  addEvent: async (event: Event) => {
    try {
      const newEvent: Event = await service.addEvent(event)
      if (newEvent) {
        set((state: State) => ({
          events: [...state.events, { ...newEvent }],
        }))
      } else {
        throw new Error('Failed to add post')
      }
    } catch (error) {
      console.error('Error adding post:', error)
    }
  },
  getEvents: async () => {
    try {
      const response: Event.EventResponse = await service.getEvents()
      const newEvents: Event.Event[] = response.socialEvents
      if (newEvents) {
        set(() => ({
          events: newEvents,
          pagination: { meta: response.meta, links: response.links },
        }))
      } else {
        throw new Error('Failed to get events')
      }
    } catch (error) {
      console.error('Error fetching events:', error)
    }
  },
  getEvent: async (uuid: string): Event.Event => {
    try {
      const retrievedEvent: Event.Event = await service.getEvent(uuid)
      if (retrievedEvent && !retrievedEvent.error) {
        localStorage.setItem('eventUuid', JSON.stringify(retrievedEvent.unique_id))
        localStorage.setItem('eventId', JSON.stringify(retrievedEvent.id))
        set((state: State) => ({
          events: [...state.events, retrievedEvent],
          event: retrievedEvent
        }))
        get().setSelectedEvent(retrievedEvent.id)
        return retrievedEvent
      } else {
        throw new Error('Failed to retrieve event')
      }
    } catch (error) {
      console.error('Error fetching event:', error)
    }
  },
  getHighlights: async (eventId: number): Event.Event => {
    try {
      get().setLoading(true)
      const retrievedEvent: Event.Event = await service.getHighlights(eventId)
      if (retrievedEvent && !retrievedEvent.error) {
        return retrievedEvent.posts.posts
      } else {
        throw new Error('Failed to retrieve highlights')
      }
    } catch (error) {
      console.error('Error fetching highlights:', error)
    } finally {
      get().setLoading(false)
    }
  },
  getSelectedEvent: (eventId): Event.Event => {
    if (eventId) {
      const eventId = get().selectedEventId
      const selectedEvent = get().events.find((event: Event.Event) => event.id === eventId)
      return selectedEvent
    }
    return null
  },
  setSelectedEvent: async (eventId: string) => {
    try {
      if (eventId) {
        set(() => ({
          selectedEvent: eventId,
        }))
      } else {
        throw new Error('No event id provided')
      }
    } catch (error) {
      console.error('Error selecting event:', error)
    }
  },
  loading: false,
  setLoading: (flag: boolean) => {
    set(() => ({
      loading: flag,
    }))
  },
})

const useEventStore = create(devtools(eventStore))

export default useEventStore
