import * as React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

interface Props {
  completed: boolean
}
const ProgressBar: React.FC<Props> = (props) => {
  const [progress, setProgress] = React.useState(0)
  const [variant, setVariant] = React.useState<'determinate' | 'indeterminate' | 'buffer' | 'query'>('determinate')

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10
        const nextValue = oldProgress + diff
        if (props.completed) {
          setVariant('indeterminate')
          return Math.min(100, 100)
        } else if (nextValue < 90) {
          return Math.min(nextValue, 100)
        } else {
          return Math.min(oldProgress, 100)
        }
      })
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [props.completed])

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress color={'secondary'} variant={variant} value={progress} />
    </Box>
  )
}
export default ProgressBar
