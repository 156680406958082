import * as React from 'react'
import moment from 'moment'
import 'moment/locale/es' // without this line it didn't work
import { useTranslation } from 'react-i18next'

interface Props {
  format?: 'fromNow' | 'normal'
  date: string
}

const Moment: React.FC<Props> = (props) => {
  const { i18n } = useTranslation()
  moment.locale(i18n.language)
  // Update the locale to customize the calendar output
  //   moment.updateLocale('en', {
  //     calendar: {
  //       lastDay: '[Yesterday] HH:mm:ss',
  //       sameDay: '[Today] HH:mm:ss',
  //       nextDay: '[Tomorrow] HH:mm:ss',
  //       lastWeek: '[Last] dddd HH:mm:ss',
  //       nextWeek: '[Next] dddd HH:mm:ss',
  //       sameElse: 'YYYY-MM-DD HH:mm:ss',
  //     },
  //   })

  //   function formatDate(inputDateStr: string) {
  //     const inputDate = moment(inputDateStr, 'YYYY-MM-DD HH:mm:ss')
  //     return inputDate.calendar()
  //   }

  // Example usage
  //   const formatted = formatDate(props.date)

  const getFormat = () => {
    switch (props.format) {
      case 'fromNow':
        return moment(props.date).fromNow()
      case 'normal':
      default:
        return moment(props.date).format('D MMM  YY')
    }
  }
  const formatted = getFormat()
  return formatted
}

export default Moment
