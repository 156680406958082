import * as React from 'react'
import { AuthenticateWithRedirectCallback } from '@clerk/clerk-react'
import { useLocation } from 'react-router-dom'

interface Props {}
const SSOCallback: React.FC<Props> = () => {
  const location = useLocation()
  // Handle the redirect flow by rendering the
  // prebuilt AuthenticateWithRedirectCallback component.
  // This is the final step in the custom OAuth flow.
  return (
    <AuthenticateWithRedirectCallback
      signInFallbackRedirectUrl={`/sign-in/sso-login${location.search}`}
    />
  )
}
export default SSOCallback
