import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useClerk } from '@clerk/clerk-react'
import { useTranslation } from 'react-i18next'
import useUserStore from '../../Store/userStore'
import { useNavigate, useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';

interface Props {}
const HeaderMenu: React.FC<Props> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { user, logout } = useUserStore()
  const { signOut } = useClerk()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clickProfile = () => {
    handleClose()
    navigate(`/profile${location.search}`)
  }
  const clickSettings = () => {
    handleClose()
    navigate(`/settings${location.search}`)
  }
  const clickLogout = async () => {
    handleClose()
    await logout()
    await signOut({ redirectUrl: '/' })
  }
  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!!user && <MenuItem onClick={clickProfile}>{t('profile')}</MenuItem>}
        <MenuItem onClick={clickSettings}>{t('settings')}</MenuItem>
        {!!user && <MenuItem onClick={clickLogout}>{t('logout')}</MenuItem>}
      </Menu>
    </div>
  )
}
export default HeaderMenu
