import * as React from 'react'
import List from '@mui/material/List'
import ViewPost from '../Post/ViewPost'
import usePostStore from '../../Store/postStore'
import { Post } from '../../Models'
import Ribbon from '../Ribbon/Ribbon'
import PostSkeleton from '../Post/PostSkeleton'
import Slide from '@mui/material/Slide'
import ProgressBar from '../ProgressBar/ProgressBar'
import MansoryMediaList from '../MediaList/MansoryMediaList';
import WovenMediaList from '../MediaList/WovenMediaList';

interface Props {
  swipe: 'right' | 'left'
}
const Home: React.FC<Props> = (props) => {
  const { addingPost, posts, getPosts, loading } = usePostStore()

  React.useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await getPosts()
  }
  const mediaPosts: Post.Media[] = []
  posts.filter((post: Post.Post) => {
      Object.keys(post.media).forEach((key: string) => {
        mediaPosts.push(post.media[parseInt(key)])
      })
    })

  return (
    <Slide direction={props.swipe} in={true} timeout={500}>
      <div>
        <Ribbon />
        {addingPost ? <ProgressBar completed={false}/> : null}
        <List sx={{p: '10px'}}>
          {posts.map((post: Post.Post, index: number) => (
            <ViewPost key={index} {...post} />
          ))}

        {/* <MansoryMediaList items={mediaPosts} posts={posts}/> */}

        {/* {posts.map((post: Post.Post, index: number) => 
          {
            return <>
            <div>{post.title}</div>
            <div>{post.content}</div>
            <WovenMediaList key={index} items={post.media} post={post}/>
            </>
          }
          )} */}
        </List>
        {loading && posts.length > 10 && <PostSkeleton />}
      </div>
    </Slide>
  )
}

export default Home
