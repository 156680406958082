import * as React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'

interface Props {}
const PostSkeleton: React.FC<Props> = () => {
  return (
    <Stack spacing={1}>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid xs={2} md={2}>
          <Skeleton variant="circular" width={40} height={40} />
        </Grid>
        <Grid xs={10} md={10}>
          <Skeleton variant="text" width={40} sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" width={60} sx={{ fontSize: '1rem' }} />
        </Grid>
        <Grid xs={12} md={12}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </Grid>
      </Grid>
      <Skeleton variant="rounded" height={300} />
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid xs={2} md={2}>
          <Skeleton variant="circular" width={40} height={40} />
        </Grid>
        <Grid xs={2} md={2}>
          <Skeleton variant="circular" width={40} height={40} />
        </Grid>
        <Grid xs={2} md={2} sx={{ marginLeft: 'auto' }}>
          <Skeleton variant="circular" width={40} height={40} />
        </Grid>
      </Grid>
    </Stack>
  )
}
export default PostSkeleton
