import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useUserStore from '../../Store/userStore'
import { useNavigate, useLocation } from 'react-router-dom'
import Slide from '@mui/material/Slide'
import logo from '../../assets/logo2.svg'
import HeaderMenu from './HeaderMenu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props {}
const Header: React.FC<Props> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useUserStore()
  const clickLogo = () => {
    if (!user) {
      navigate(`/register${location.search}`)
    } else {
      navigate('/')
    }
  }
  const goBack = () => {
    navigate(-1)
  }

  return (
    <Slide direction="left" in={true} timeout={500}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="inherit">
          <Toolbar>
            {/* <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              onClick={clickLogo}
            >
                <img src={logo} alt='Momenti App' onClick={clickLogo} style={{ cursor: 'pointer', maxHeight: '30px' }} />
            </Typography> */}
            <ArrowBackIcon onClick={goBack} />
            <div style={{marginLeft: 'auto'}}>
            <HeaderMenu />
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </Slide>
  )
}
export default Header
