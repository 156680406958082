import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './i18n'
import { ClerkProvider } from '@clerk/clerk-react'

const frontendApi = 'pk_test_Z3Jvd2luZy1tYXJ0aW4tNTYuY2xlcmsuYWNjb3VudHMuZGV2JA'

const element = document.getElementById('root') as HTMLElement

const root = ReactDOM.createRoot(element)

root.render(
  <ClerkProvider publishableKey={frontendApi}>
    <App />
  </ClerkProvider>
)
