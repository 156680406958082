import React from 'react'
import useCameraStore from '../../Store/cameraStore'
import CameraButtons from './CameraButtons'
import Box from '@mui/material/Box'
import SwipeableEdgeDrawer from '../SwipeableDrawer/SwipeableDrawer'

interface Props {}

const CameraVideo: React.FC<Props> = () => {
  const videoRef = React.useRef(null)
  const canvasRef = React.useRef(null)
  const mediaRecorderRef = React.useRef(null)
  const { show, toggleCamera, isFrontCamera } = useCameraStore()

  const getCameraStream = async () => {
    try {
      const facingMode = isFrontCamera ? 'environment' : 'user'
      if (!videoRef?.current) return
      if (videoRef.current.srcObject) {
        // Stop any existing video streams
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop())
      }
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode } })
      videoRef.current.srcObject = stream
      videoRef.current.play()
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: 'video/webm;codecs=vp9',
      })
      // Set the canvas size based on the video stream
      const track = stream.getVideoTracks()[0]
      const settings = track.getSettings()
      canvasRef.current.width = settings.width
      canvasRef.current.height = settings.height
    } catch (err) {
      console.error('Error accessing the camera: ', err)
    }
  }

  React.useEffect(() => {
    getCameraStream()
  }, [])

  React.useEffect(() => {
    getCameraStream()
  }, [isFrontCamera])

  if (!show) return null

  return (
    // <ViewModal style={{ height: '100%', alignContent: 'center' }}>
    <SwipeableEdgeDrawer open={show} onClose={() => toggleCamera(false)} height="100%">
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100vw',
          textAlign: 'center',
          bgcolor: 'black',
          height: '100%',
          alignContent: 'center',
        }}
      >
        <video ref={videoRef} style={{ width: '100%' }} autoPlay></video>
        <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
        <CameraButtons
          webcamRef={videoRef}
          canvasRef={canvasRef}
          mediaRecorderRef={mediaRecorderRef}
        />
      </Box>
    </SwipeableEdgeDrawer>
  )
}

export default CameraVideo
