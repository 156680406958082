import * as React from 'react'
import { useClerk } from '@clerk/clerk-react'
import useUserStore from '../../Store/userStore'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../../Components/Loader/Loader'
import { UserResource } from '@clerk/types'

interface Props {}
const SSOLogin: React.FC<Props> = () => {
  const location = useLocation()
  const { user } = useClerk()
  const { socialLogin } = useUserStore()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (user) {
      console.log(user)
      handleSocialLogin(user)
    }
  }, [user])

  const handleSocialLogin = async (user: UserResource) => {
    const success = await socialLogin({
      name: user.fullName ?? '',
      email: user.primaryEmailAddress?.emailAddress ?? '',
      password: '123456q!',
      image: user.hasImage ? user.imageUrl : '',
      social_account: user.externalAccounts[0]?.provider ?? ''
    })
    const sp = new URLSearchParams(location.search)
    const redirectTo = sp.get('redirectTo')
    if (success) {
      navigate(redirectTo ? redirectTo : '/')
    } else {
      navigate('/register'+redirectTo)
    }
  }
  return <Loader />
}
export default SSOLogin
