import * as React from 'react'
import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules'

export default function Hero() {
  const { t } = useTranslation()
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(2rem, 10vw, 2rem)',
              fontWeight: 100
            }}
          >
            Your Moments,&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Our Momenti
            </Typography>
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {t('landing_page_description')}
          </Typography>
        </Stack>
        <Box
          id="imageContainer"
          sx={(theme) => ({
            width: '100%',
          })}
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, /*Pagination,*/ Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <Grid container spacing={2} sx={{p: '0 30px'}}>
                <Grid xs={6} md={6} sx={{ alignSelf: 'center' }}>
                  <Typography variant="subtitle1">{t('landing_page_hero_slide1_title')}</Typography>
                </Grid>
                <Grid xs={6} md={6} sx={{ alignSelf: 'center' }}>
                  <Box
                    id="image"
                    sx={(theme) => ({
                      mt: { xs: 8, sm: 10 },
                      alignSelf: 'center',
                      height: { xs: 200, sm: 400 },
                      width: '100%',
                      backgroundImage:
                        theme.palette.mode === 'light'
                          ? 'url("/images/mockup_invitation_scan.png")'
                          : 'url("/images/mockup_invitation_scan.png")',
                      // backgroundSize: 'cover',
                      backgroundSize: 'contain',
                      backgroundPosition: 'right',
                      backgroundRepeat: 'no-repeat',
                      borderRadius: '10px',
                      // outline: '1px solid',
                      // outlineColor:
                      //   theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
                      // boxShadow:
                      //   theme.palette.mode === 'light'
                      //     ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                      //     : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    })}
                  />
                </Grid>
              </Grid>
            </SwiperSlide>
            <SwiperSlide>
              <Grid container spacing={2} sx={{p: '0 30px'}}>
                <Grid xs={6} md={6} sx={{ alignSelf: 'center' }}>
                  <Typography variant="subtitle1">{t('landing_page_hero_slide2_title')}</Typography>
                </Grid>
                <Grid xs={6} md={6} sx={{ alignSelf: 'center' }}>
                  <Box
                    id="image"
                    sx={(theme) => ({
                      mt: { xs: 8, sm: 10 },
                      alignSelf: 'center',
                      height: { xs: 200, sm: 400 },
                      width: '100%',
                      backgroundImage:
                        theme.palette.mode === 'light'
                          ? 'url("/images/upload_momenti_mockup.png")'
                          : 'url("/images/upload_momenti_mockup.png")',
                      // backgroundSize: 'cover',
                      backgroundSize: 'contain',
                      backgroundPosition: 'right',
                      backgroundRepeat: 'no-repeat',
                      borderRadius: '10px',
                      // outline: '1px solid',
                      // outlineColor:
                      //   theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
                      // boxShadow:
                      //   theme.palette.mode === 'light'
                      //     ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                      //     : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    })}
                  />
                </Grid>
              </Grid>
            </SwiperSlide>
            <SwiperSlide>
              <Grid container spacing={2} sx={{p: '0 30px'}}>
                <Grid xs={6} md={6} sx={{ alignSelf: 'center' }}>
                  <Typography variant="subtitle1">{t('landing_page_hero_slide3_title')}</Typography>
                </Grid>
                <Grid xs={6} md={6} sx={{ alignSelf: 'center' }}>
                  <Box
                    id="image"
                    sx={(theme) => ({
                      mt: { xs: 8, sm: 10 },
                      alignSelf: 'center',
                      height: { xs: 200, sm: 400 },
                      width: '100%',
                      backgroundImage:
                        theme.palette.mode === 'light'
                          ? 'url("/images/mockup_app_home.png")'
                          : 'url("/images/mockup_app_home.png")',
                      // backgroundSize: 'cover',
                      backgroundSize: 'contain',
                      backgroundPosition: 'right',
                      backgroundRepeat: 'no-repeat',
                      borderRadius: '10px',
                      // outline: '1px solid',
                      // outlineColor:
                      //   theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
                      // boxShadow:
                      //   theme.palette.mode === 'light'
                      //     ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                      //     : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    })}
                  />
                </Grid>
              </Grid>
            </SwiperSlide>
            {/* <SwiperSlide>
              <Box
                id="image"
                sx={(theme) => ({
                  mt: { xs: 8, sm: 10 },
                  alignSelf: 'center',
                  height: { xs: 200, sm: 700 },
                  width: '100%',
                  backgroundImage:
                    theme.palette.mode === 'light'
                      ? 'url("/images/close-up-woman-taking-photo-her-friend-mobile-phone.jpg")'
                      : 'url("/images/close-up-woman-taking-photo-her-friend-mobile-phone.jpg")',
                  backgroundSize: 'cover',
                  borderRadius: '10px',
                  outline: '1px solid',
                  outlineColor:
                    theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
                  boxShadow:
                    theme.palette.mode === 'light'
                      ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                      : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                })}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Box
                id="image"
                sx={(theme) => ({
                  mt: { xs: 8, sm: 10 },
                  alignSelf: 'center',
                  height: { xs: 200, sm: 700 },
                  width: '100%',
                  backgroundImage:
                    theme.palette.mode === 'light'
                      ? 'url("/images/event_qr_code_photo.jpg")'
                      : 'url("/images/event_qr_code_photo.jpg")',
                  backgroundSize: 'cover',
                  borderRadius: '10px',
                  outline: '1px solid',
                  outlineColor:
                    theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
                  boxShadow:
                    theme.palette.mode === 'light'
                      ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                      : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                })}
              />
            </SwiperSlide> */}
          </Swiper>
        </Box>
      </Container>
    </Box>
  )
}
