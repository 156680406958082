import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import useEventStore from '../../Store/eventStore'
import { Event } from '../../Models'
import Moment from '../Moment/Moment'
import { CardContent, Grid } from '@mui/material'
import SwipeableEdgeDrawer from '../SwipeableDrawer/SwipeableDrawer'
import Skeleton from '@mui/material/Skeleton'
import HeaderMenu from '../Header/HeaderMenu';

interface Props {}

const EventHeader: React.FC<Props> = () => {
  const { selectedEvent, getEvent, event } = useEventStore()
  const [showEventDetails, setShowEventDetails] = React.useState(false)

  if (!event) {
    const selectedEventId = localStorage.getItem('eventUuid')
    if (selectedEventId != null && selectedEventId?.length > 0) {
      getEvent(JSON.parse(selectedEventId))
    }
    return (
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={2} sx={{ m: '10px' }}>
          <Grid xs={12} md={12}>
            <Skeleton variant="rounded" height={400} />
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <Card
          sx={{ width: '100%', m: '10px' }}
          // onClick={() => setShowEventDetails(!showEventDetails)}
        >
          <Box sx={{ position: 'relative' }}>
            <CardMedia
              component="img"
              sx={{height: 400}}
              src={event.media[0]?.urls.full}
              srcSet={event.media[0]?.srcset}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                bgcolor: 'rgba(0, 0, 0, 0.54)',
                color: 'white',
                padding: '5px 10px',
              }}
            >
              <Grid container sx={{height: '100%'}}>
                <Grid xs={10} md={10}>
                  <Typography variant="h5" sx={{textAlign: 'left'}}>{event.title}</Typography>
                </Grid>
                <Grid xs={2} md={2}>
                  <HeaderMenu />
                </Grid>
                <Grid xs={9} md={9}>
                  <Typography variant="body2" sx={{textAlign: 'left'}}>
                    <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
                  </Typography>
                </Grid>
                <Grid xs={3} md={3} sx={{ marginLeft: 'auto', alignContent: 'center' }}>
                  <Typography variant="body2">
                    <Moment format="fromNow" date={event.date} />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </Box>
      <SwipeableEdgeDrawer open={showEventDetails} onClose={() => setShowEventDetails(false)}>
        <Box sx={{ p: 1 }}>
          <Card sx={{ width: '100%' }} onClick={() => setShowEventDetails(!showEventDetails)}>
            <CardMedia
              component="img"
              height="100%"
              src={event.media[0]?.urls.full}
              srcSet={event.media[0]?.srcset}
            />
            <CardContent>
              <Grid container>
                <Grid xs={12} md={12}>
                  <Typography variant="h5">{event.title}</Typography>
                  <Typography variant="body2">
                    <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
                  </Typography>
                  <Typography variant="body2">
                    <Moment date={event.date} />
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </SwipeableEdgeDrawer>
    </>
  )
}

export default EventHeader
