import * as React from 'react'
// import BottomNavigation from '../Components/BottomNavigation/BottomNavigation'
import Content from '../Components/Content/Content'
import AddPost from '../Components/Post/AddPost'
import Camera from '../Components/Camera/Camera'
import EventHeader from '../Components/EventHeader/EventHeader'
import Slide from '@mui/material/Slide'
import FloatingUpload from '../Components/FloatingUpload/FloatingUpload'

export default function Event() {
  return (
    <Slide direction="right" in={true} timeout={500}>
      <div>
        <EventHeader />
        <Content />
        <AddPost />
        <FloatingUpload/>
        {/* <BottomNavigation /> */}
        <Camera />
      </div>
    </Slide>
  )
}
