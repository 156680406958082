import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import usePostStore from '../../Store/postStore'
import ProgressBar from '../ProgressBar/ProgressBar'
import MediaList from '../MediaList/MediaList'
import useCameraStore from '../../Store/cameraStore'
import SwipeableEdgeDrawer from '../SwipeableDrawer/SwipeableDrawer'
import CssBaseline from '@mui/material/CssBaseline'
import { useTranslation } from 'react-i18next'

interface Props {}

const AddPost: React.FC<Props> = () => {
  const { preview, setPreview, files, setFiles } = useCameraStore()
  const { t } = useTranslation()
  const [title, setTitle] = React.useState('')
  const [content, setContent] = React.useState('')
  const [buttonEnabled, setButtonEnabled] = React.useState(true)
  const { addPost } = usePostStore()
  const [completed, setCompleted] = React.useState(false)
  const [showProgressBar, setShowProgressBar] = React.useState(false)

  const handleSubmitPost = async () => {
    const post = {
      title: title,
      content: content,
      media: files,
    }
    // setShowProgressBar(true)
     addPost(post)
    // setCompleted(true)
    onClose()
    // setTimeout(() => {
    //   setShowProgressBar(false)
    //   onClose()
    // }, 500)
  }

  const onClose = () => {
    setPreview([])
    setFiles([])
  }

  React.useEffect(() => {
    // if (content !== '' && title !== '') {
    //   setButtonEnabled(true)
    // } else {
    //   setButtonEnabled(false)
    // }
  }, [content, title, preview])

  if (preview.length === 0) {
    return null
  }
  return (
    <SwipeableEdgeDrawer open={preview.length > 0} onClose={onClose}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { p: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <CssBaseline />
        {showProgressBar ? (
          <ProgressBar completed={completed} />
        ) : (
          <Box sx={{m: 2}}>
            {preview.length > 0 && <MediaList items={preview} />}
            <div>
              <TextField
                required
                id="outlined-required"
                placeholder={t('title')}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
              />
              <TextField
                id="outlined-required"
                placeholder={t('content')}
                onChange={(e) => setContent(e.target.value)}
                fullWidth
              />
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmitPost}
              disabled={!buttonEnabled}
              sx={{width: '100%', m: '10px 0'}}
            >
              {t('upload')}
            </Button>
            <Button variant="outlined" color="secondary" onClick={onClose} sx={{width: '100%', m: '10px 0'}}>
              {t('cancel')}
            </Button>
          </Box>
        )}
      </Box>
    </SwipeableEdgeDrawer>
  )
}
export default AddPost
