import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SendIcon from '@mui/icons-material/Send'
import Avatar from '@mui/material/Avatar'
import { Card, CardHeader, InputBase } from '@mui/material'

import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import { Comment } from '../../Models/post'
import useUserStore from '../../Store/userStore'
import usePostStore from '../../Store/postStore'
import { useTranslation } from 'react-i18next'
import { Post } from '../../Models'
import Moment from '../Moment/Moment'

interface Props {
  postId: number
  comments: Comment[]
}

const Comments: React.FC<Props> = (props) => {
  const { user } = useUserStore()
  const { addComment } = usePostStore()
  const { t } = useTranslation()
  const [comments, setComments] = React.useState(props.comments || [])
  const [comment, setComment] = React.useState('')

  const sendComment = async () => {
    if (comment !== '') {
      const newComments = await addComment(props.postId, comment)
      setComments(newComments)
      setComment('')
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
      }}
    >
      {comments.map((comment: Post.Comment) => (
        <Card sx={{ width: '100%' }} key={comment.id}>
          <CardHeader
            avatar={
              comment.creator.image ? (
                <Avatar alt={comment.creator.name} src={comment.creator.image} />
              ) :
              <Avatar alt={comment.creator.name} sx={{ width: 30, height: 30 }}>
                {comment.creator.name.substring(0, 1).toUpperCase()}
              </Avatar>
            }
            title={comment.creator.name}
            subheader={<Moment format="fromNow" date={comment.date} />}
          />
          <Typography variant="subtitle2" sx={{ p: 1 }}>
            {comment.content}
          </Typography>
        </Card>
      ))}

      <Paper component="form" sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={`${t('comment_as')}${user?.name}`}
          inputProps={{ 'aria-label': 'search google maps' }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton  sx={{ p: '10px' }} aria-label="send" onClick={sendComment}>
          <SendIcon />
        </IconButton>
      </Paper>
    </Box>
  )
}

export default Comments
