import * as React from 'react'
import useUserStore from '../Store/userStore'
import EventHeader from '../Components/EventHeader/EventHeader'
import { List } from '@mui/material'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'

interface Props {}
const EventList: React.FC<Props> = () => {
  const { user } = useUserStore()

  if (!user) return null

  return (
    <Slide direction={'left'} in={true} timeout={500}>
      <Box>
        <CssBaseline />
        <List>
          <EventHeader />
        </List>
      </Box>
    </Slide>
  )
}
export default EventList
