import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'

const Loader: React.FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <CssBaseline />
    <CircularProgress color="secondary" />
  </Box>
)
export default Loader
